<template>
  <div class="center">
    <p> {{ $parent.language.StartGame.headding }} </p>
    <p v-bind:class="{ isred: nameError }"> {{ $parent.language.StartGame.body }} </p>

    <div class="container" v-bind:class="{ green: clicked }">
      <div class="row">
        <div class="">
          <input class="input" placeholder="Name" v-model="name" />
        </div>
      </div>
      <div class="row">
        <input class="input" placeholder="Work Email" v-model="email" />
      </div>
      <div class="row">
        <input class="input" placeholder="Work Mobile" v-model="mobile" />
      </div>
      <div class="row">
        <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
          @click="SendTeamName()">{{ $parent.language.StartGame.go }}</button>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>

import { callApi } from '../plugins/callApi.js'
export default {
  name: 'StartGameDemo',
  props: {
    msg: String,
    dbid: String
  },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      nameError: false,
      clicked: false
    }
  },
  methods: {
    async SendTeamName() {
      if (this.teamName == "") {
        this.nameError = true;
        this.clicked = false;
      } else {
        this.nameError = false;
        this.clicked = true;
        var setup = await callApi("newgame", this.license, { "name": this.name, extra: { "name": this.name, "email": this.email, "mobile": this.mobile }, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        //  await axios.post(process.env.VUE_APP_ip + '/newgame', { "name": this.name, "email": this.email, "mobile": this.mobile, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        this.$emit('updatestage', "")
      }

    }
  }
}
</script>


