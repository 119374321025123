<template>
  <div class="timerpartent">
    <div class="timer" v-bind:class="{ timerDone: timerDone }">
      {{ formatedCountdown || "countdown over" }}
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import * as moment from "moment";
import "moment-duration-format";

export default {
  name: "TimerItem",
  props: {
    pause: Number,
    penalty: Boolean,
  },
  data() {
    return {
      countdown: 900,
      timerDone: false,
    };
  },
  mounted() {
    this.countdown = this.$parent.gameSettings.timer;
    setInterval(() => {
      if (this.pause == 0) {
        this.countdown -= 1;
        this.$emit("updateTimer", this.countdown);

        if (this.penalty === true) {
          if (this.$parent.extraPenalty === true) {
            this.countdown -= 60;
            this.$parent.extraPenalty = false;
            this.$emit("penaltyDone", "");
          } else {
            this.countdown -= 30;
            this.$emit("penaltyDone", "");
          }
        }
      }
      if (!this.countdown) {
        console.log("count down done");
        this.timerDone = true;
        // clearInterval(stopCountdown)
      }
    }, 1000);
  },
  computed: {
    formatedCountdown() {
      return moment
        .duration(this.countdown, "seconds")
        .format("mm:ss", { trim: false });
    },
  },
};
</script>