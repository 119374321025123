<template :style="dynamicStyle">
  <template v-if="stage == 'agentCode'">
    <setup @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" @videoBackStage="setupBackButton" />
  </template>

  <template v-if="stage == 'popups'">
    <popups @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" />
  </template>

    <template v-if="stage == 'phoneAudio'">
    <phoneAudio @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" />
  </template>

      <template v-if="stage == 'stage27'">
    <phoneCode @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" gameName="stage27" />
  </template>

  <template v-if="stage == 'StartGame'">
    <StartGame @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @gameStages="setStages" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

  <template v-if="stage == 'introvid'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="introvid" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'languageChoice'">
    <language @dbidfu="dbidupdate" @langChoise="LanguageChoice" @updatestage="updateStage" />
  </template>

  <template v-if="stage == 'stage2'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage" video="stage2"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage3'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage" video="stage3"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage4'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage4.body" :button="this.language.stage4.button" />
  </template>

  <template v-if="stage == 'stage5'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage5"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage6'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage" video="stage6"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage7'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage7.body" :button="this.language.stage7.button" />
  </template>

  <template v-if="stage == 'stage8'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage8"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage9'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage" video="stage9"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage10'">
    <popups @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      gameName="stage10" @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage11'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage11" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage12'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage12"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

    <template v-if="stage == 'stage121'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage121" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage13'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage13" @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'stage14'">
    <wifiGame :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage14"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage15'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage15" @startTimer="enableTimer" />
  </template>


  <template v-if="stage == 'stage16'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage16"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage17'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage17" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage18'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage18.body" :button="this.language.stage18.button" />
  </template>


  <template v-if="stage == 'stage19'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage19" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage20'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage20"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage21'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage21" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage22'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage22"
      @startTimer="enableTimer" />
  </template>


  <template v-if="stage == 'stage22env'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage22env.body" :button="this.language.stage22env.button" />
  </template>

  <template v-if="stage == 'stage23'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage23" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage24'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage24"
      @Penalty="addPenalty" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage25'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage25"
      @Penalty="addPenalty" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage26'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage26" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage27old'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage27"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage28'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage28" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage29'">
    <password :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage29"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage30'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage30.body" :button="this.language.stage30.button" />
  </template>

  <template v-if="stage == 'stage31'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage31" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage32'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage32" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'endScreen'">
    <endScreen @reset="endGameReset" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

<template v-if="stage == 'deepfake1'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="deepfake1" @startTimer="enableTimer" />
  </template>

<template v-if="stage == 'deepfake3'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="deepfake3" @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'deepfake2'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="deepfake2"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

    <template v-if="stage == 'deepfake4'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.deepfake4.body" :button="this.language.deepfake4.button" />
  </template>

      <template v-if="stage == 'qrcode'">
    <qrcode @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.qrcode.body" :button="this.language.qrcode.button" />
  </template>

<!-- open banking  -->


  <template v-if="stage == 'stage13ob'">
    <wifiGame :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage13ob"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage14ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage14" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage15ob'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage15ob"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage16ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage16" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage17ob'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage17ob"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage18ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage18" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage19ob'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage19ob"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage20ob'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage20ob.body" :button="this.language.stage20ob.button" />
  </template>

  <template v-if="stage == 'stage21ob'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage21ob"
      @Penalty="addPenalty" @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'stage22ob'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage22ob"
      @Penalty="addPenalty" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage23ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage23" @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'stage24ob'">
    <twoButtons :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage24ob"
      @startTimer="enableTimer" @Penalty="addPenalty" />
  </template>

  <template v-if="stage == 'stage25ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage25" @startTimer="enableTimer" />
  </template>

        <template v-if="stage == 'stage26ob'">
    <phoneCode @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" gameName="stage26ob" />
  </template>

  <template v-if="stage == 'stage27ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage27ob" @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'stage28ob'">
    <password :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage28ob"
      @startTimer="enableTimer" />
  </template>

    <template v-if="stage == 'stage29ob'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage29ob.body" :button="this.language.stage29ob.button" />
  </template>

        <template v-if="stage == 'stage291ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage291ob" @startTimer="enableTimer" />
  </template>

      <template v-if="stage == 'stage292ob'">
    <draganddrop @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      gameName="stage292ob" :gameData="this.language.stage291ob"  />
  </template>

  <template v-if="stage == 'stage30ob'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="stage30" @startTimer="enableTimer" />
  </template>

     <template v-if="stage == 'stage31ob'">
    <qrcodedy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      gameName="stage31ob" />
  </template>

  <template v-if="stage == 'stage32ob'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="stage32ob"
      @startTimer="enableTimer" />
  </template>


<!-- open banking  -->


<!-- lidl -->
    <template v-if="stage == 'stage23lidl'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage23lidl.body" :button="this.language.stage23lidl.button" />
  </template>

  <template v-if="stage == 'lidlsizzle'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="lidlsizzle" @startTimer="enableTimer" />
  </template>

  <template v-if="stage == 'stage281lidl'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.stage281lidl.body" :button="this.language.stage281lidl.button" />
  </template>


<!-- lidl -->
  <!--  -->
  <template v-if="timer == 1 && this.gameSettings.clock != 'clock'">
    <Timer :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />

  </template>

  <template v-if="timer == 1 && this.gameSettings.clock == 'clock'">
    <timerClock :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />
  </template>

  <template v-if="stage != 'agentCode'">
    <cornerLogo @updatestage="updateStage" @previousstage="previousStage" />
  </template>

  <template v-if="stage != 'agentCode' && stage != 'mobileNumber'">
    <background />
  </template>
</template>

<script>





import axios from 'axios'
import cornerLogo from './components/cornerLogo.vue'
import setup from './components/setup.vue'
import StartGame from './components/StartGame.vue'
import TextView from './components/TextView.vue'
import TextView2 from './components/TextView2.vue'
import Timer from './components/timer.vue'
import hackerVideo from './components/hackerVideo.vue'
import bruteVideo from './components/bruteVideo.vue'
import bruteClue from './components/bruteClue.vue'
import bruteClueManual from './components/bruteClueManual.vue'
import encryption from './components/encryption.vue'
import socialVideo from './components/socialVideo.vue'
import socialQuestions from './components/socialQuestions.vue'
import encryptionVideo from './components/encryptionVideo.vue'
import messagesVideo from './components/messagesVideo.vue'
import messagesGame from './components/messagesGame.vue'
import finalHacker from './components/finalHacker.vue'
import endGame from './components/endGame.vue'
import endScreen from './components/endScreen.vue'
import trainingVideo from './components/trainingVideo.vue'
import language from './components/language.vue'
const videos = require('./plugins/video.js')
const logo = require('./plugins/logo.js')
import passwordVideo from './components/passwordVideo.vue'
import password from './components/password.vue'
import explainerVideo from './components/explainerVideo.vue'
import TextViewdy from './components/TextViewdy.vue'
import Questions from './components/Questions.vue'
import MultiQuestion from './components/QuestionsMultiChoice.vue'
import timerClock from './components/timerClock.vue'
import SingleQuestion from './components/SingleQuestion.vue'
import SingleQuestionLong from './components/SingleQuestionLong.vue'
import videody from './components/videody.vue'
import midClue from './components/midClue.vue'
import QuestionsTickChoice from './components/QuestionsTickChoice.vue'
import mobileNumber from './components/mobileNumber.vue'
import background from './components/background.vue'
import StartGameDemo from './components/StartGameDemo.vue'
import twoButtons from './components/twoButtons.vue'
import popups from './components/popups.vue'
import phoneAudio from './components/phoneAudio.vue'
import phoneCode from './components/phoneCode.vue'
import wifiGame from './components/wifiGame.vue'
import qrcode from './components/qrCode.vue'
import draganddrop from './components/draganddrop.vue'
import qrcodedy from './components/dyQrcode.vue'

export default {
  name: 'App',
  components: {
    passwordVideo,
    password,
    cornerLogo,
    StartGame,
    StartGameDemo,
    TextView,
    TextView2,
    hackerVideo,
    bruteVideo,
    Timer,
    encryption,
    socialVideo,
    socialQuestions,
    bruteClue,
    bruteClueManual,
    encryptionVideo,
    messagesVideo,
    messagesGame,
    finalHacker,
    endGame,
    endScreen,
    setup,
    language,
    trainingVideo,
    explainerVideo,
    TextViewdy,
    Questions,
    MultiQuestion,
    timerClock,
    SingleQuestion,
    videody,
    midClue,
    SingleQuestionLong,
    QuestionsTickChoice,
    mobileNumber,
    background,
    twoButtons,
    popups,
    phoneAudio,
    phoneCode,
    wifiGame,
    qrcode,
    draganddrop,
    qrcodedy
  },
  created() {
    document.title = "Beat The Hacker: Web of Lies";
  },
  data() {
    return {
      info: '',
      name: '',
      startsound: false,
      stage: "agentCode",
     // stage: "phoneCode",
      timer: 0,
      pauseTimer: 0,
      timerValue: 0,
      penalty: false,
      penaltyCount: 0,
      dbid: "62816a01c0014f5adad845d5",
      language: '',
      langOptions: '',
      chosenLanguage: '',
      videoData: [],
      logoData: [],
      videoPath: 'XL',
      GlobalLanguageContentObj: {},
      gameSettings: {},
      gameBackground: "#172031",
      buttonColor: "#f29412",
      logoFile: "logo1.png",
      gameStages: [],
      mobileNumber: "",
      background: 1,
      backButton: [],
      otp: "",
      extraPenalty: false

    }
  },
  async mounted() {

    this.videoData = await videos.setup()
    this.logoData = await logo.setup()

  },
  methods: {
    endGameReset() {
      console.log("reset")
      this.info = ''
      this.name = ''
      this.startsound = false
      this.stage = "agentCode"
      this.timer = 0
      this.pauseTimer = 0
      this.timerValue = 0
      this.penalty = false
      this.penaltyCount = 0
      this.dbid = "62816a01c0014f5adad845d5"
    },
    penaltyDone() {
      this.penalty = false
      this.penaltyCount += 1
    },
    addPenalty() {
      this.penalty = true
    },
    enableTimer(value) {
      this.timer = value

    },
    setupBackButton(value) {
      this.backButton = value;
    },
    updateTimer(value) {
      this.timerValue = value

    },
    getNotifications() {
      return axios
        .post(process.env.VUE_APP_ip + '/gamedata', { "dbid": this.dbid })
    },
    manageTimer(value) {
      this.pauseTimer = value;
    },
    dbidupdate(value) {
      this.dbid = value
    },
    setLanguage(value) {
      this.langOptions = value
    },
    setStages(value) {
      this.gameStages = value
    },
    SavemobileNumber(value) {
      console.log(value)
      this.mobileNumber = value
      this.updateStage()
    },
    updateStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) + 1
      this.stage = this.gameStages[newIndex]
    },
    previousStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) - 1
      this.stage = this.gameStages[newIndex]
    },
    LanguageChoice(value) {
      this.chosenLanguage = value
      this.language = this.GlobalLanguageContentObj[value]
    },
    VideoPath(value) {
      this.videoPath = value
    },
    GlobalLanguageContent(value) {
      this.GlobalLanguageContentObj = value
    },
    gameSetting(value) {
      this.gameSettings = value

      // here we can set any global chnages
      this.gameSettings.background = value.background
      document.body.style.backgroundColor = this.gameSettings.background;
      document.body.style.color = this.gameSettings.textColor;
      // document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.gameSettings.containerColor
      this.buttonColor = value.buttonColor
      this.logoFile = this.gameSettings.logoFile
      this.containerColor = this.gameSettings.containerColor


    },

  }
}
</script>

<style>
@import './assets//styles.css';
</style>



