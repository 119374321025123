<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <div v-html="this.gameData.body"></div>
            <img alt="Xcapism Learning" :src="qrcode" />

            <div class="field field--button btnwidth">
              <button
                class="button"
                type="submit"
                :style="{ 'background-color': $parent.buttonColor }"
                @click="StartGame()"
              >
                {{ this.gameData.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import axios from "axios";

export default {
  name: "QRCode",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  data() {
    return {
      teamName: "",
      gameData: [],
      qrcode: "",
    };
  },
  mounted() {
    console.log(this.gameName);
    console.log(this.$parent.language);
    console.log(this.$parent.language[this.gameName]);
    this.gameData = this.$parent.language[this.gameName];
    console.log(this.gameData);
    this.qrcode = this.$parent.logoData[this.gameData.qrcode];
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    this.$emit("pauseTimer", 0);
    if (this.$parent.stage == this.$parent.gameSettings.timerStartStage) {
      this.$emit("startTimer", 1);
      this.$emit("pauseTimer", 1);
    }
  },
  methods: {
    StartGame() {
      this.$emit("updatestage", "");
    },
  },
};
</script>


