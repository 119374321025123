<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />

  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame videobackground">
          <div class="containerPhone">
            <div class="phone">
              <div class="phone-screen">
                <template v-if="phoneState == 'home'">
                  <div class="apps">
                    <div class="app" @click="switchApp('otp')">
                      <div class="app-icon"></div>
                      <div class="app-label">OTP Code APP</div>
                    </div>
                  </div>
                </template>
                <template v-if="phoneState == 'otp'">
                  <div class="authenticator">
                    <header class="authenticator-header">
                      <h3>Authenticator</h3>
                    </header>

                    <main class="authenticator-main">
                      <ul class="account-list">
                        <li
                          v-for="account in accounts"
                          :key="account.id"
                          class="account-item"
                        >
                          <div class="account-info">
                            <h5>{{ account.name }}</h5>
                            <span class="otp">{{ this.otp }}</span>
                          </div>
                          <div class="timer">
                            <button
                              @click="this.generateOTP(account.secret)"
                              class="add-account-btn"
                            >
                              Get New Code
                            </button>
                          </div>
                        </li>
                      </ul>
                    </main>
                  </div>
                  <div class="homebtnLine">
                    <button class="homebtn" @click="this.switchApp('home')">
                      <i class="fa fa-home"></i>
                    </button>
                  </div>
                </template>
                <template v-if="phoneState == 'pass'"></template>
                <template v-if="phoneState == 'pin'">
                  <div class="phoneScreenCaller">
                    Enter Pin:<br /><br />
                    <div class="Pincontainer">
                      <div class="pinbox" v-html="pin[0]"></div>
                      <div class="pinbox" v-html="pin[1]"></div>
                      <div class="pinbox" v-html="pin[2]"></div>
                      <div class="pinbox" v-html="pin[3]"></div>
                    </div>
                  </div>
                  <div class="phoneScreenInner">
                    <div class="keypad">
                      <div class="key" @click="this.pinNumber('1')">1</div>
                      <div class="key" @click="this.pinNumber('2')">2</div>
                      <div class="key" @click="this.pinNumber('3')">3</div>
                      <div class="key" @click="this.pinNumber('4')">4</div>
                      <div class="key" @click="this.pinNumber('5')">5</div>
                      <div class="key" @click="this.pinNumber('6')">6</div>
                      <div class="key" @click="this.pinNumber('7')">7</div>
                      <div class="key" @click="this.pinNumber('8')">8</div>
                      <div class="key" @click="this.pinNumber('9')">9</div>
                      <div class="key" @click="this.pinNumber('*')">*</div>
                      <div class="key" @click="this.pinNumber('0')">0</div>
                      <div class="key" @click="this.pinNumber('#')">#</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <template v-if="phoneState == 'otp'">
              <button
                class="mobileNext angled-out-button"
                @click="this.gotOTP()"
              >
                Next
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { callApi } from "../plugins/callApi.js";
export default {
  name: "SingleQuestion",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  data() {
    return {
      inputText: "",
      pin: ["_", "_", "_", "_"],
      phoneState: "pin",
      otp: "",
      accounts: [
        {
          id: 1,
          name: "Jones Email",
          secret: "76986876967",
        },
      ],
    };
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName];
    this.generateOTP("76986876967");
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName + "Start",
      dbid: this.dbid,
    });
  },
  methods: {
    addAccount() {
      const newAccountId = accounts.value.length + 1;
      accounts.value.push({
        id: newAccountId,
        name: `Account ${newAccountId}`,
        secret: `secret${newAccountId}`,
      });
    },
    generateOTP(secret) {
      // Mock function for OTP generation based on secret
      var newOtp = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0");
      this.otp = newOtp;
      return newOtp;
    },
    pinNumber(number) {
      var indexNo = this.pin.indexOf("_");
      if (indexNo > -1) {
        this.pin[indexNo] = number;
      }
      var indexCheck = this.pin.indexOf("_");
      if (indexCheck == -1) {
        this.checkPin();
      }
    },
    async checkPin() {
      var testPin = this.pin.join("");
      if (testPin == this.gameData.answer) {
        this.phoneState = "home";
      } else {
        var setup = await callApi("incorrectattempt", this.license, {
          gameName: this.gameName + "Question1",
          dbid: this.dbid,
        });
        this.pin = ["_", "_", "_", "_"];
      }
    },
    switchApp(app) {
      this.generateOTP("76986876967");
      this.phoneState = app;
    },
    async gotOTP() {
      this.$parent.otp = this.otp;
      var setup = await callApi("updateprogress", this.license, {
        gameName: this.gameName + "End",
        dbid: this.dbid,
      });
      this.$emit("updatestage", "");
    },
  },
};
</script>
  
  <style scoped>
/* Container Flex Layout */
.containerPhone {
  display: flex;
  flex-direction: row; /* Ensure they are side by side */
  align-items: center;
  justify-content: space-between; /* Spread items evenly */
  width: 100vh;
  padding: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.left-section {
  flex: 1; /* Takes equal space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section {
  flex: 1; /* Takes equal space */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Phone CSS */
.phone {
  width: 300px;
  height: 600px;
  border: 5px solid #8d8989;
  border-radius: 20px;
  position: relative;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.key {
  background: #333;
  color: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s;
}

.key:hover {
  background: #444;
}

.phoneRing {
  width: 200px;
  height: 400px;
  border: 5px solid #000;
  border-radius: 20px;
  position: relative;
  animation: shake 0.5s ease-in-out infinite alternate;
}

.phone-screen {
  width: 90%;
  height: 90%;
  background: #222;
  border-radius: 10px;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
}

.phoneScreenInner {
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  padding-top: 110px;
}

.phone-antenna {
  width: 30%;
  height: 10px;
  background: #000;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
}

.phoneScreenCaller {
  color: #fff;
  font-size: 20px;
  padding-top: 30px;
  text-align: center;
}

.phoneButtons {
  display: flex;
  justify-content: space-around;
  padding-top: 120px;
}

.answerBtn {
  height: 50px;
  line-height: 0px;
  width: 50px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  text-align: center;
  cursor: pointer;
  padding-top: -80px;
}

.rejectBtn {
  height: 50px;
  line-height: 0px;
  width: 50px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #af241d;
  color: white;
  text-align: center;
  cursor: pointer;
  padding-top: -80px;
}

.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 8px;
  border-bottom: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
}

.x {
  font-size: 30px;
}

/* Phone Animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  25% {
    transform: translate(-2px, -2px) rotate(-2deg);
  }
  50% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.Pincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
}

.pinbox {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
  padding-top: 20px;
  font-size: 30px;
}

pinbox:first-of-type {
  padding-right: 0;
}

pinbox:last-of-type {
  padding-right: 0;
}

.apps {
  display: flex;
  justify-content: space-around; /* Space between the two apps */
  width: 100%;
  margin-top: 10px;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

/* App Icon Styling */
.app-icon {
  width: 70px;
  height: 70px;
  background-color: #4caf50;
  border-radius: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* App Label Styling */
.app-label {
  color: white;
  font-size: 12px;
}

.authenticator {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: auto;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.authenticator-header {
  text-align: center;
}

.account-list {
  list-style-type: none;
  padding: 0;
}

.account-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.account-info h2 {
  margin: 0;
}

.otp {
  font-size: 1.5rem;
  font-weight: bold;
}

.timer {
  display: flex;
  align-items: center;
  padding-right: 50px;
}

progress {
  margin-right: 10px;
  width: 100px;
}

.add-account-btn {
  display: block;
  width: 100%;
  padding: 5px;
  margin-top: 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.add-account-btn:hover {
  background-color: #357ae8;
}

.homebtnLine {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 170px;
  background-color: #2c3441;
}

.homebtn {
  background-color: rgb(100, 109, 118); /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 5px 6px; /* Some padding */
  font-size: 30px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over */
.homebtn:hover {
  background-color: RoyalBlue;
}

.mobileNext {
  position: absolute;
  margin-top: 500px;
  left: 150px;
}

.angled-out-button {
  background-color: #db1b4f; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px;
  font-size: 30px;
  border: none;
  float: both;
  clear: both;
  cursor: pointer;
  font-family: Arial, sans-serif;
  position: relative;
  transition: background-color 0.3s ease;
  clip-path: polygon(
    10% 0%,
    100% 0%,
    100% 100%,
    0% 100%
  ); /* Creates the outward angle */
}

.angled-out-button:hover {
  background-color: #cb3a61; /* Darker blue on hover */
}
</style>
  