<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame ">
          <div class="innerframe extrapadding">
            <div v-html="$parent.language.encryption.body"></div>
          </div>
          <div>
            <div v-bind:class="{ green: questionok, red: questionwrong }" class="container encpanel ">

              <div class="row">
                <div class="field encryptionLetter" hidden>
                  <input class="input" value="" disabled />
                </div>
                <div class="field encryptionLetter">
                  <input class="input encBorder" style="text-transform: uppercase;" placeholder="?" maxlength="1"
                    v-model="letter1" ref="firstbox"
                    v-on:keyup="this.$refs.secondbox.focus(); this.$refs.secondbox.select(); makeUpper(0); this.populateClue()" />
                </div>
                <div class="field encryptionLetter">
                  <input class="input encBorder" style="text-transform: uppercase;" placeholder="?" maxlength="1" v-model="letter2"
                    ref="secondbox"
                    v-on:keyup="this.$refs.thirdbox.focus(); this.$refs.thirdbox.select(); makeUpper(1); this.populateClue()" />
                </div>
                <div class="field encryptionLetter">
                  <input class="input encBorder" style="text-transform: uppercase;" placeholder="?" maxlength="1" v-model="letter3"
                    ref="thirdbox"
                    v-on:keyup="this.$refs.fourthbox.focus(); this.$refs.fourthbox.select(); makeUpper(2); this.populateClue()" />
                </div>
                <div class="field encryptionLetter">
                  <input class="input encBorder" style="text-transform: uppercase;" placeholder="?" maxlength="1" v-model="letter4"
                    ref="fourthbox"
                    v-on:keyup="this.$refs.firstbox.focus(); this.$refs.firstbox.select(); makeUpper(3); this.populateClue()" />
                </div>
                <div class="field encryptionLetter" v-if="this.$parent.language.encryption.clue4 != ''">
                  <input class="input encBorder" style="text-transform: uppercase;" placeholder="?" maxlength="1" v-model="letter5"
                    ref="fithbox" v-on:blur="makeUpper(4);"
                    v-on:keyup="this.$refs.firstbox.focus(); this.$refs.firstbox.select(); makeUpper(4); this.populateClue()" />
                </div>
                <div class="">
                  <button class="button" type="submit" style="border-color:#f29412; border-style: solid;" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkcode()">{{ $parent.language.encryption.check }}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'encryptionGame',
  props: {
    msg: String,
    dbid: String
  },
  async mounted() {

    if (this.$parent.language.encryption.clue0color != "") {
      this.$refs.firstbox.style.color = this.$parent.language.encryption.clue0Color;
      this.$refs.firstbox.style.borderColor = this.$parent.language.encryption.clue0Color;
    }

    if (this.$parent.language.encryption.clue1color != "") {
      this.$refs.secondbox.style.color = this.$parent.language.encryption.clue1Color;
      this.$refs.secondbox.style.borderColor = this.$parent.language.encryption.clue1Color;
    }

    if (this.$parent.language.encryption.clue2color != "") {
      this.$refs.thirdbox.style.color = this.$parent.language.encryption.clue2Color;
      this.$refs.thirdbox.style.borderColor = this.$parent.language.encryption.clue2Color;
    }

    if (this.$parent.language.encryption.clue3color != "") {
      this.$refs.fourthbox.style.color = this.$parent.language.encryption.clue3Color;
      this.$refs.fourthbox.style.borderColor = this.$parent.language.encryption.clue3Color;
    }

    // if (this.$parent.language.encryption.clue5color != "") {
    //   this.$refs.fithbox.style.color = this.$parent.language.encryption.clue4Color;
    //   this.$refs.fithbox.style.borderColor = this.$parent.language.encryption.clue4Color;
    // }

    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "encryptionStart", "dbid": this.dbid })
    this.$emit('pauseTimer', 0)

    this.$refs.firstbox.focus()
    //  axios
    //  .post('http://192.168.1.224:3000/updatestage', {"stage": 7})
    //  setTimeout(() => {  this.GameMove() }, 120000);

    while (this.randNumber.length != this.$parent.gameSettings.encryptionVisibleLetters) {
      var randomnumber = Math.ceil(Math.random() * 5)
      if (this.randNumber.indexOf(randomnumber) === -1) {
        this.randNumber.push(randomnumber)
      }


      this.populateClue()

    }

  },
  data() {
    return {
      letter1: "",
      letter2: "",
      letter3: "",
      letter4: "",
      questionok: false,
      questionwrong: false,
      randNumber: []
    }
  },
  methods: {
    makeUpper(number) {
      if (number == 0) {
        this.letter1 = this.letter1.toUpperCase()
      }
      if (number == 1) {
        this.letter2 = this.letter2.toUpperCase()
      }
      if (number == 2) {
        this.letter3 = this.letter3.toUpperCase()
      }
      if (number == 3) {
        this.letter4 = this.letter4.toUpperCase()
      }
      if (this.$parent.language.encryption.clue4 != ''){
      if (number == 4) {
        this.letter5 = this.letter5.toUpperCase()
      }
    }
    },
    async checkcode() {

      if (this.letter1.toLowerCase() == this.$parent.language.encryption.clue0 && this.letter2 == this.$parent.language.encryption.clue1 && this.letter3 == this.$parent.language.encryption.clue2 && this.letter4 == this.$parent.language.encryption.clue3 ) {
        console.log("pass")
        this.questionwrong = false
        this.questionok = true
        axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": "encryptionEnd", "dbid": this.dbid })
        this.$emit('updatestage', "")
      } else {
        console.log("error")
        axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": "encryption", "dbid": this.dbid })
        this.questionwrong = true
      }

    },
    populateClue() {
      for (var number of this.randNumber) {
        switch (number) {
          case 1:
            this.letter1 = this.$parent.language.encryption.clue0.toUpperCase()
            break;
          case 2:
            this.letter2 = this.$parent.language.encryption.clue1.toUpperCase()
            break;
          case 3:
            this.letter3 = this.$parent.language.encryption.clue2.toUpperCase()
            break;
          case 4:
            this.letter4 = this.$parent.language.encryption.clue3.toUpperCase()
            break;
          case 5:
            this.letter5 = this.$parent.language.encryption.clue4.toUpperCase()
            break;
        }
      }
    }
  }
}
</script>



