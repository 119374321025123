<template>
  <div class="backgroundLogoCont">


    <!-- <img alt="Vue logo" src="../assets/fishLeft.png" class="backgroundLogoLeft">
        <img alt="Vue logo" src="../assets/fishRight.png" class="backgroundLogoRight"> -->
  </div>
</template>
  
<style>
@import '../assets/form.css';
</style>
  
  