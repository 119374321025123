<template>
  <img alt="Codfather Logo" src="../assets/mainLogo.png" height="350" style="margin-top: 40px;">
  <div class="center">
    <p v-bind:class="{ isred: nameError }"> Enter your Agent Number to get started </p>

    <div class="container">
      <template v-if="error != ''">
        <div class="row">
          <p>{{ error }}</p>
        </div>
      </template>
      <div class="row">
        <input class="input" placeholder="Agent Number" v-model="license" />
      </div>
      <div class="row">
        <button class="button" type="submit" @click="GameSetup()">Go</button>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>

import { callApi } from '../plugins/callApi.js'

export default {
  name: 'setupGame',
  props: {

  },
  data() {
    return {
      teamName: "",
      nameError: false,
      license: "",
      boxid: "",
      error: ""

    }
  },
  async mounted() {


    if (localStorage.getItem("license") !== null && localStorage.getItem("boxid") !== null) {
      this.license = localStorage.getItem("datadash-license")
      this.boxid = localStorage.getItem("datadash-boxid")
      await this.GameSetup()
    }
  },
  methods: {
    async GameSetup() {
      this.error = ""

      var setup = await callApi("gamesetup", this.license)

      if (setup.error) {
        console.log(setup.error)
        this.error = setup.error
      } else {
        localStorage.setItem('datadash-license', this.license);
        localStorage.setItem('datadash-boxid', this.boxid);
        this.$emit('dbidfu', setup.dbid)
        this.$emit('vidoePathSetup', setup.videoPath)
        this.$emit('langup', setup.language)
        this.$emit('langdata', setup.gameText)
        this.$emit('gamedata', setup.gameSettings)
        this.$emit('gameStages', setup.stages)
        this.$emit('updatestage', "")
         this.$emit('videoBackStage', setup.VideoBackStages)//setup.VideoBackStages)

        if (setup.gameSettings.customFont) {
          var appObj = document.getElementById("app");
          appObj.style.fontFamily = setup.gameSettings.customFont;
        }
      }






    }
  }
}
</script>


