<template>
  <img
    alt="BTH Logo"
    :src="$parent.logoData[$parent.logoFile]"
    height="350"
    style="margin-top: 40px"
  />
  <div class="center">
    <p>{{ $parent.language.StartGame.headding }}</p>
    <p v-bind:class="{ isred: nameError }">
      {{ $parent.language.StartGame.body }}
    </p>

    <div class="container" v-bind:class="{ green: clicked }">
      <div class="row">
        <div class="field">
          <input
            class="input"
            :placeholder="$parent.language.StartGame.placeholder"
            v-model="teamName"
          />
        </div>
        <div class="field field--button">
          <button
            class="button"
            type="submit"
            :style="{ 'background-color': $parent.buttonColor }"
            @click="SendTeamName()"
          >
            {{ $parent.language.StartGame.go }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import { callApi } from "../plugins/callApi.js";
export default {
  name: "StartGame",
  props: {
    msg: String,
    dbid: String,
  },
  data() {
    return {
      teamName: "",
      nameError: false,
      clicked: false,
    };
  },
  methods: {
    async SendTeamName() {
      if (this.teamName == "") {
        this.nameError = true;
        this.clicked = false;
      } else {
        this.nameError = false;
        this.clicked = true;

        var setup = await callApi("newgame", this.license, {
          name: this.name,
          extra: {
            name: this.name,
            email: this.email,
            mobile: this.mobile,
            teamName: this.teamName,
          },
          dbid: this.dbid,
          chosenLanguage: this.$parent.chosenLanguage,
        });
        this.$emit("updatestage", "");
      }
    },
  },
};
</script>


