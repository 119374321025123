
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <div class="wifi-scanner">
              <h3>Available Wi-Fi Networks:</h3>
              <template v-if="error">
                <p style="color: red">{{ error }}</p>
              </template>
              <!-- Simulate scanning state -->
              <div class="loader">
                <div v-if="scanning" class="loader">Scanning...</div>

                <!-- List networks after scanning is complete -->

                <ul v-else class="">
                  <li
                    v-for="network in networks"
                    :key="network.id"
                    class="center"
                    @click="selectNetwork(network)"
                  >
                    {{ network.name }}
                  </li>
                </ul>
              </div>

              <!-- Password input dialog -->
              <div v-if="selectedNetwork" class="password-dialog">
                <h5>Enter Password for {{ selectedNetwork.name }}:</h5>
                <input
                  v-model="password"
                  type="password"
                  placeholder="Enter password"
                />
                <button @click="connect" class="wifibutton">Connect</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    

<style>
@import "../assets/form.css";

.wifi-scanner {
  text-align: center;
  margin-top: 10px !important;
}

.loader {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  align-content: center;
}

ul {
  list-style-type: none;
  padding: 0;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.center {
  text-align: center;
  align-content: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
}

li {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  width: 200px;
  border-radius: 5px;
  border-color: aliceblue;
  border-style: solid;
  cursor: pointer;
}

li:hover {
  border-color: rgb(166, 191, 213);
}

.password-dialog {
  margin-top: 10px;
}

input {
  padding: 10px;
  font-size: 16px;
  width: 200px;
}

.wifibutton {
  padding: 10px 20px;
  margin-top: 0px;
  font-size: 16px;
  cursor: pointer;
}
</style>

<script>
import { callApi } from "../plugins/callApi.js";

export default {
  name: "SingleQuestion",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  async mounted() {
    this.$emit("startTimer", 1);
    this.gameData = this.$parent.language[this.gameName];
    setTimeout(() => {
      for (var network in this.gameData.networkNames) {
        this.networks.push(this.gameData.networkNames[network]);
      }
      // this.networks = [
      //   // { id: 1, name: "VM3792215" },
      //   // { id: 2, name: "SkyWifi-42868" },
      //   // { id: 3, name: "SkyWifi-98603" },
      //   // { id: 4, name: "Drop it like it's hotspot" },
      //   // { id: 5, name: "My Coffee Shop WiFi" },
      //   // { id: 6, name: "FastNet 5G" },
      // ];
      this.scanning = false;
    }, 3000); // Simulating a 3 second scan
    // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName + "Question1",
      dbid: this.dbid,
    });
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    this.$emit("pauseTimer", 0);
  },
  data() {
    return {
      gameData: [],
      question: "",
      question1ok: "",
      question1wrong: "",
      scanning: true,
      networks: [],
      selectedNetwork: null,
      password: "",
    };
  },
  methods: {
    selectNetwork(network) {
      this.error = null;
      this.selectedNetwork = network;
    },
    async connect() {
      this.error = null;
      if (this.password) {
        if (this.selectedNetwork.name !== this.gameData.correct) {
          this.$emit("Penalty", "");
          this.error = "Incorrect Network - 30 Second Penalty";
          var setup = callApi("incorrectattempt", this.license, {
            gameName: this.gameName + "Question1",
            dbid: this.dbid,
          });
        } else {
          if (this.password.toLowerCase() === this.gameData.answer) {
            var setup = await callApi("updateprogress", this.license, {
              gameName: this.gameName + "Question1",
              dbid: this.dbid,
            });
            this.$emit("updatestage", "");
          } else {
            this.$emit("Penalty", "");
            this.error = "Incorrect Password";
            var setup = callApi("incorrectattempt", this.license, {
              gameName: this.gameName + "Question1",
              dbid: this.dbid,
            });
          }
        }

        // alert(
        //   `Connecting to ${this.selectedNetwork.name} with password: ${this.password}`
        // );
        this.selectedNetwork = null;
        this.password = "";
      } else {
        this.error = "A password is required";
      }
    },
    async checkQuestion(number) {
      if (number == 1) {
        this.question1ok = "";
        this.question1wrong = "";
        if (this.question.toLowerCase() == this.gameData.answer) {
          this.question1ok = true;
        } else {
          this.question1wrong = true;
          this.$emit("Penalty", "");
          //   axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question1", "dbid": this.dbid })
          var setup = await callApi("incorrectattempt", this.license, {
            gameName: this.gameName + "Question1",
            dbid: this.dbid,
          });
        }
      }

      if (this.question1ok == true) {
        // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "End", "dbid": this.dbid })
        var setup = await callApi("updateprogress", this.license, {
          gameName: this.gameName + "Question1",
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      } else {
        // axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName, "dbid": this.dbid })
        var setup = await callApi("incorrectattempt", this.license, {
          gameName: this.gameName + "Question1",
          dbid: this.dbid,
        });
      }
    },
  },
};
</script>


