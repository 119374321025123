  <template>
  <div id="app">
    <!-- Popup 1 -->
    <div v-if="activePopups.includes(1)" class="popup" :style="popupStyles[1]">
      <div class="popup-header">
        <span></span>
        <button class="close-btn" @click="popupAction('1-dodgy')">✕</button>
      </div>
      <div v-html="gameData.popup1.text" class="popupBody"></div>
      <div class="popupBtns">
        <button
          class="button-37 btnRed"
          role="button"
          @click="popupAction('1-dodgy')"
        >
          {{ gameData.popup1.dodgy }}
        </button>
        <button
          class="button-37 btnGreen"
          role="button"
          @click="popupAction('1-legit')"
        >
          {{ gameData.popup1.legit }}
        </button>
      </div>
    </div>

    <!-- Popup 2 -->
    <div v-if="activePopups.includes(2)" class="popup" :style="popupStyles[2]">
      <div class="popup-header">
        <span></span>
        <button class="close-btn" @click="popupAction('2-dodgy')">✕</button>
      </div>
      <div v-html="gameData.popup2.text" class="popupBody"></div>
      <div class="popupBtns">
        <button
          class="button-37 btnRed"
          role="button"
          @click="popupAction('2-dodgy')"
        >
          {{ gameData.popup2.dodgy }}
        </button>
        <button
          class="button-37 btnGreen"
          role="button"
          @click="popupAction('2-legit')"
        >
          {{ gameData.popup2.legit }}
        </button>
      </div>
    </div>

    <!-- Popup 3 -->
    <div v-if="activePopups.includes(3)" class="popup" :style="popupStyles[3]">
      <div class="popup-header">
        <span></span>
        <button class="close-btn" @click="popupAction('3-dodgy')">✕</button>
      </div>
      <div v-html="gameData.popup3.text" class="popupBody"></div>
      <div class="popupBtns">
        <button
          class="button-37 btnRed"
          role="button"
          @click="popupAction('3-dodgy')"
        >
          {{ gameData.popup3.dodgy }}
        </button>
        <button
          class="button-37 btnGreen"
          role="button"
          @click="popupAction('3-legit')"
        >
          {{ gameData.popup3.legit }}
        </button>
      </div>
    </div>

    <!-- Popup 4 -->
    <div v-if="activePopups.includes(4)" class="popup" :style="popupStyles[4]">
      <div class="popup-header">
        <span></span>
        <button class="close-btn" @click="popupAction('4-dodgy')">✕</button>
      </div>
      <div v-html="gameData.popup4.text" class="popupBody"></div>
      <div class="popupBtns">
        <button
          class="button-37 btnRed"
          role="button"
          @click="popupAction('4-dodgy')"
        >
          {{ gameData.popup4.dodgy }}
        </button>
        <button
          class="button-37 btnGreen"
          role="button"
          @click="popupAction('4-legit')"
        >
          {{ gameData.popup4.legit }}
        </button>
      </div>
    </div>
  </div>
</template>

  <script>
import { callApi } from "../plugins/callApi.js";
export default {
  name: "SingleQuestion",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  data() {
    return {
      activePopups: [], // Tracks which popups are active
      popupStyles: {}, // Holds the random styles for each popup
      gameData: [],
    };
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName];
    this.showAllPopups();
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName + "Start",
      dbid: this.dbid,
    });
  },
  methods: {
    showAllPopups() {
      // Open all popups by adding their numbers to the activePopups array
      this.activePopups = [1, 2, 3, 4];

      // Randomize the position of each popup
      this.randomizePopupPosition(1);
      this.randomizePopupPosition(2);
      this.randomizePopupPosition(3);
      this.randomizePopupPosition(4);
    },
    closePopup(popupNumber) {
      // Close a specific popup by removing its number from the activePopups array
      this.activePopups = this.activePopups.filter(
        (popup) => popup !== popupNumber
      );
    },
    async popupAction(action) {
      var arr = action.toString().split("-");
      var correct = false;

      if (this.gameData["popup" + arr[0]].asnwer == arr[1]) {
        var setup = await callApi("updateprogress", this.license, {
          gameName: this.gameName + "popup" + arr[0],
          dbid: this.dbid,
        });
        correct = true;
      } else {
        var setup = callApi("incorrectattempt", this.license, {
          gameName: this.gameName + "Question1",
          dbid: this.dbid,
        });
        if (this.gameData.extraPenalty === true) {
          this.$parent.extraPenalty = true;
          this.$emit("Penalty", "");
        } else {
          this.$emit("Penalty", "");
        }
      }
      this.activePopups = this.activePopups.filter(
        (popup) => popup !== parseInt(arr[0])
      );

      if ("force" in this.gameData["popup" + arr[0]] && correct == true) {
        this.randomizePopupPosition(parseInt(arr[0]));
        this.activePopups.push(parseInt(arr[0]));
      }

      if (this.activePopups.length == 0) {
        var setup = await callApi("updateprogress", this.license, {
          gameName: this.gameName + "End",
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      }
    },
    randomizePopupPosition(popupNumber) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Generate random top and left positions within the window bounds
      const randomTop =
        Math.floor(Math.random() * (window.innerHeight - 400)) + "px";
      const randomLeft =
        Math.floor(Math.random() * (window.innerWidth - 800)) + "px";

      this.popupStyles = {
        ...this.popupStyles,
        [popupNumber]: {
          top: randomTop,
          left: randomLeft,
          position: "fixed",
        },
      };
    },
  },
};
</script>

  <style scoped>
/* .popup {
  width: 500px;
  padding: 20px;
  background-color: white;
  color: #000;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
} */

.popup {
  width: 500px;
  height: 200px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
  margin-top: 0%;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;

  border-radius: 10px; /* Rounded corners for the entire popup */
  color: #000;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #030202; /* Optional border to separate header */
  border-top-left-radius: 10px; /* Rounded corners for the top-left */
  border-top-right-radius: 10px; /* Rounded corners for the top-right */
  background-color: #4600c7; /* Light background color for the header */
  margin-bottom: 30px;
}

.popupBody {
  padding-left: 20px;
  padding-right: 20px;
}

.popupBtns {
  margin-left: 20px;
  padding-top: 30px;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: red; /* Red color for the X button */
  cursor: pointer;
}

.close-btn:hover {
  color: darkred; /* Darker red on hover */
}

button {
  margin: 5px;
}

.button-37 {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }
}

.btnRed {
  background-color: #d6281c;
  border: 1px solid #d6281c;
}

.btnGreen {
  background-color: #13aa52;
  border: 1px solid #13aa52;
}
</style>
