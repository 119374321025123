<template>
  <div class="game-container">
    <div class="middle">
      <div class="center">
        <div class="bodyTxt" v-html="this.gameData.body"></div>
        <div class="game-container">
          <div class="column questions">
            <div class="box-headding">
              <h3>{{ this.gameData.questionHeadding }}</h3>
            </div>
            <div
              v-for="question in questions"
              :key="question.id"
              class="question-box"
            >
              <p>{{ question.text }}</p>

              <div
                class="drop-area"
                @drop="onDrop($event, question.id)"
                @dragover="allowDrop($event)"
              >
                <div v-if="question.answer" class="dropped-answer">
                  {{ question.answer.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="column answers">
            <div class="box-headding">
              <h3>{{ this.gameData.answerHeadding }}</h3>
            </div>
            <div
              v-for="answer in answers"
              :key="answer.id"
              class="answer-box"
              :draggable="true"
              @dragstart="onDragStart($event, answer)"
            >
              <p>{{ answer.text }}</p>
            </div>
          </div>
          <div class="controls">
            <button class="mobileNext angled-out-button" @click="checkAnswers">
              {{ this.gameData.checkBtn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { callApi } from "../plugins/callApi.js";
export default {
  name: "dardAndDrop",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
    gameData: Object,
  },
  data() {
    return {
      questions: [],
      answers: [],
      draggedAnswer: null,
      gameData: [],
      incorrect: 0,
      dragged: 0,
    };
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName];
    //  this.answers = this.gameData.answers;
    for (var answer in this.gameData.answers) {
      this.answers.push(this.gameData.answers[answer]);
    }
    for (var question in this.gameData.questions) {
      this.questions.push(this.gameData.questions[question]);
    }

    this.shuffle(this.answers);
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName + "Start",
      dbid: this.dbid,
    });
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName,
      dbid: this.dbid,
    });
  },
  methods: {
    resetAnswers() {
      this.answers = [];
      //  this.questions.length = 0;
      this.draggedAnswer = null;
      for (var answer in this.gameData.answers) {
        this.answers.push(this.gameData.answers[answer]);
      }
      for (var question in this.questions) {
        this.questions[question].answer = null;
      }
      this.shuffle(this.answers);
    },
    allowDrop(event) {
      event.preventDefault();
    },
    onDragStart(event, answer) {
      this.draggedAnswer = answer;
    },
    onDrop(event, questionId) {
      const question = this.questions.find((q) => q.id === questionId);
      if (question && this.draggedAnswer) {
        this.dragged++;
        question.answer = this.draggedAnswer;
        this.answers = this.answers.filter(
          (answer) => answer.id !== this.draggedAnswer.id
        );
        this.draggedAnswer = null;
      }
    },
    shuffle(array) {
      let currentIndex = array.length;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
    },
    async checkAnswers() {
      this.incorrect = 0;
      this.questions.forEach((question) => {
        if (
          question.answer &&
          question.answer.id !== question.correctAnswerId
        ) {
          // Move the incorrect answer back to the answers column
          this.incorrect++;
          this.dragged = this.dragged - 1;
          this.answers.push(question.answer);
          question.answer = null;
        }
      });
      if (this.incorrect == 0 && this.dragged == this.questions.length) {
        var setup = await callApi("updateprogress", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      } else {
        var setup = await callApi("incorrectattempt", this.license, {
          gameName: this.gameName + "Question1",
          dbid: this.dbid,
        });
      }
    },
  },
};
</script>

<style scoped>
.game-container {
  font-size: 15px;
  display: flex;

  gap: 20px;
  color: #fff;
}

.column {
  width: 400px;

  padding: 10px;
}

.question-box,
.answer-box {
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;
}

.answer-box {
  text-align: center;
  padding-left: 0px;
  cursor: grab;
}

.drop-area {
  min-height: 50px;
  margin-top: 5px;
  border: 1px solid #ec1414;
  text-align: center;
  padding-top: 5px;
  vertical-align: middle;
}

.dropped-answer {
  /* background-color: #dff0d8; */
  padding: 5px;
  border-radius: 3px;
}

.controls {
  height: 200px;

  position: absolute;
  right: 300px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.box-headding {
  text-align: center;
}

.mobileNext {
  position: absolute;
  margin-top: 500px;
  left: 150px;
}
.angled-out-button {
  background-color: #db1b4f; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px;
  font-size: 30px;
  border: none;
  float: both;
  clear: both;
  cursor: pointer;
  font-family: Arial, sans-serif;
  position: relative;
  transition: background-color 0.3s ease;
  clip-path: polygon(
    10% 0%,
    100% 0%,
    100% 100%,
    0% 100%
  ); /* Creates the outward angle */
}

.angled-out-button:hover {
  background-color: #cb3a61; /* Darker blue on hover */
}

.bodyTxt {
  margin-top: 50px;
  font-size: 30px;
}
</style>