
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <template v-if="error == 0">
              <div v-html="gameData.body" class="headdingPadding"></div>

              <div
                class="question"
                v-bind:class="{ green: question1ok, red: question1wrong }"
              >
                <div class="PassRowContainer">
                  <div class="box1">
                    <div class="row btnwidth rowlabel">
                      <div>Old Password:</div>
                    </div>

                    <div class="row rowlabel socialquestion">
                      <input
                        class="input"
                        placeholder=""
                        v-model="oldPassword"
                        @paste="disablePaste"
                        @copy="disableCopy"
                      />
                    </div>
                  </div>
                  <div class="box2">
                    <div class="row btnwidth rowlabel">
                      <div>OTP Code:</div>
                    </div>

                    <div class="row rowlabel socialquestion">
                      <input
                        class="input"
                        placeholder=""
                        v-model="optcodebox"
                        @paste="disablePaste"
                        @copy="disableCopy"
                      />
                    </div>
                  </div>
                </div>
                <div class="row btnwidth rowlabel">
                  <div v-html="gameData.newPassword"></div>
                </div>

                <div class="row btnwidth rowlabel socialquestion">
                  <input
                    class="input boxspacerbottom"
                    placeholder=""
                    v-model="newPassword"
                    @keyup="checkPassword"
                    @paste="disablePaste"
                    @copy="disableCopy"
                  />
                </div>

                <div
                  class="row rowlabel socialquestion strengthPosition"
                  id="passwordStrength"
                >
                  <input
                    class="input passwordStrengthBox"
                    placeholder=""
                    v-model="passwordMessage"
                    :style="{ 'background-color': passwordColor }"
                    v-bind:class="{
                      fieldRed: isWeak,
                      fieldRed: isStrong,
                    }"
                  />
                </div>
                <div class="row btnwidth rowlabel">
                  <div v-html="gameData.newPasswordretype"></div>
                </div>
                <div class="row btnwidth rowlabel socialquestion">
                  <input
                    class="input"
                    placeholder=""
                    v-model="newPasswordretype"
                    @paste="disablePaste"
                    @copy="disableCopy"
                  />
                </div>

                <div class=" ">
                  <div class="fieldSingleBtn boxspacertop">
                    <button
                      class="button"
                      id="subBtn"
                      type="submit"
                      :style="{ 'background-color': $parent.buttonColor }"
                      @click="savePassword()"
                      :disabled="btnDisabled"
                    >
                      {{ gameData.check }}
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="headdingPadding">{{ this.errormessage }}<br /></div>
              <div class="container center">
                <table width="40%">
                  <tr>
                    <td>
                      <button
                        class="button"
                        type="submit"
                        :style="{ 'background-color': $parent.buttonColor }"
                        @click="closeError()"
                      >
                        Ok
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
/* @import "../assets/form.css"; */

.PassRowContainer {
  display: flex;
  justify-content: space-between;
}

.box1,
.box2 {
  width: 45%; /* Set width for each box */
  padding: 10px;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

.passwordStrengthBox {
  text-align: center;
  width: 300px;
  font-size: 20px;
  padding: 0px;
}

.boxspacerbottom {
  margin-bottom: 20px;
}

.boxspacertop {
  margin-top: 20px;
}

.strengthPosition {
  margin-left: 50%;
}
</style>

<script>
import { callApi } from "../plugins/callApi.js";

export default {
  name: "password",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName];
    this.$emit("startTimer", 1);
    this.gameData = this.$parent.language[this.gameName];
    this.optcodebox = this.$parent.otp;
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;

    // document.querySelector("#subBtn").disabled = true;
    // document.querySelector("#passwordStrength").className = "fieldRed";
    // document.querySelector("#subBtn").className = "buttonDisabled";
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName + "Start",
      dbid: this.dbid,
    });

    this.$emit("pauseTimer", 0);
  },
  data() {
    return {
      newPassword: "",
      oldPassword: "",
      newPasswordretype: "",
      passwordColor: "red",
      passwordMessage: "Weak",
      gameData: [],
      error: 0,
      optcodebox: "",
      isWeak: false,
      isStrong: false,
      btnDisabled: false,
    };
  },
  methods: {
    disablePaste(event) {
      event.preventDefault();
    },
    disableCopy(event) {
      event.preventDefault();
    },
    checkPassword(passphrase) {
      const strongPassphraseRegex = /^(?=\b\w+(?:\s+\w+){3,}\b).{15,}$/;

      console.log(this.newPassword);
      if (this.newPassword.length >= 13) {
        this.passwordColor = "green";
        this.passwordMessage = "Strong";
        this.isStrong = true;
        this.isWeak = false;
        this.btnDisabled = false;
        // document.querySelector("#passwordStrength").className = "fieldGreen";
        // document.querySelector("#subBtn").className = "button";
        // document.querySelector("#subBtn").disabled = false;
        return "Strong";
      } else {
        this.passwordColor = "red";
        this.passwordMessage = "Weak";
        this.isStrong = false;
        this.isWeak = true;
        this.btnDisabled = true;
        // document.querySelector("#passwordStrength").className = "fieldRed";
        // document.querySelector("#subBtn").className = "buttonDisabled";
        // document.querySelector("#subBtn").disabled = true;
        return "Weak";
      }
    },
    checkPasswordold() {
      let strongPassword = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      );
      let mediumPassword = new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
      );
      // .field:first-child:after
      if (strongPassword.test(this.newPassword)) {
        this.passwordColor = "green";
        this.passwordMessage = "Strong";
        document.querySelector("#passwordStrength").className = "fieldGreen";
        document.querySelector("#subBtn").className = "button";
        document.querySelector("#subBtn").disabled = false;
      } else if (mediumPassword.test(this.newPassword)) {
        this.passwordColor = "blue";
        this.passwordMessage = "Medium";
        document.querySelector("#passwordStrength").className = "fieldBlue";
        document.querySelector("#subBtn").className = "buttonDisabled";
        document.querySelector("#subBtn").disabled = true;
      } else {
        this.passwordColor = "red";
        this.passwordMessage = "Weak";
        document.querySelector("#passwordStrength").className = "fieldRed";
        document.querySelector("#subBtn").className = "buttonDisabled";
        document.querySelector("#subBtn").disabled = true;
      }
    },
    async closeError() {
      this.error = 0;
      this.newPassword = "";
      this.newPasswordretype = "";
      this.oldPassword = "";
      this.passwordColor = "red";
      this.passwordMessage = "Weak";
      await this.checkPassword();
    },
    async savePassword() {
      if (this.newPassword != this.newPasswordretype) {
        this.error = 1;
        this.errormessage = "New passwords do not match.";
      }

      if (this.optcodebox != this.$parent.otp) {
        this.error = 1;
        this.errormessage = "OTP code is incorrect.";
        this.optcodebox = this.$parent.otp;
      }

      if (this.oldPassword.toLowerCase() != this.gameData.oldpassword) {
        this.error = 1;
        this.errormessage = "The old password is incorrect.";
      }

      if (this.error == 0) {
        var setup = await callApi("updateprogress", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      }
    },
  },
};
</script>


