
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <template v-if="error == 0">
              <div v-html="gameData.body" class="headdingPadding"></div>

              <div class="container center">
                <div class="row">
                  <table width="40%">
                    <tr>
                      <td>
                        <button
                          class="button btnOver"
                          type="submit"
                          :style="{ 'background-color': $parent.buttonColor }"
                          @click="buttonclick(1)"
                        >
                          {{ gameData.button1 }}
                        </button>
                      </td>

                      <td>
                        <button
                          class="button btnOver"
                          type="submit"
                          :style="{ 'background-color': $parent.buttonColor }"
                          @click="buttonclick(2)"
                        >
                          {{ gameData.button2 }}
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-html="gameData.popup" class="headdingPadding"></div>
              <div class="container center">
                <table width="40%">
                  <tr>
                    <td>
                      <button
                        class="button"
                        type="submit"
                        :style="{ 'background-color': $parent.buttonColor }"
                        @click="closeError(1)"
                      >
                        Ok
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";

.btnOver {
  width: 500px !important;
}

.btnOver:last-child {
  margin-left: 40px;
}
</style>

<script>
import { callApi } from "../plugins/callApi.js";

export default {
  name: "twoButtons",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  async mounted() {
    this.$emit("startTimer", 1);
    this.gameData = this.$parent.language[this.gameName];
    // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, {
      gameName: this.gameName,
      dbid: this.dbid,
    });
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;
    this.$emit("pauseTimer", 0);
  },
  data() {
    return {
      gameData: [],
      question: "",
      question1ok: "",
      question1wrong: "",
      error: 0,
    };
  },
  methods: {
    async buttonclick(number) {
      var correctButton = this.gameData.correct;
      if (number == correctButton) {
        callApi("updateprogress", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.$emit("updatestage", "");
      } else {
        this.error = 1;
        callApi("incorrectattempt", this.license, {
          gameName: this.gameName,
          dbid: this.dbid,
        });
        this.$emit("Penalty", "");
        if (this.gameData.extraPenalty === true) {
          this.$parent.extraPenalty = true;
        }
      }
    },
    closeError() {
      this.error = 0;
    },
  },
};
</script>


