<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame videobackground">
          <video
            ref="videoPlayer"
            id="videoPlayer"
            autoplay
            class="videoPlayer"
            @ended="onEnd"
          >
            <source
              :src="
                this.videoData[$parent.videoPath][$parent.chosenLanguage][
                  this.video
                ]
              "
              type="video/mp4"
            />
            <track
              :src="
                this.videoData[$parent.videoPath][$parent.chosenLanguage][
                  this.video + 'Subs'
                ]
              "
              kind="subtitles"
              srclang="en"
              label="English"
              default
            />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import axios from "axios";

export default {
  name: "videoPlayer",
  props: {
    msg: String,
    dbid: String,
    videoData: Object,
    video: String,
  },
  async mounted() {
    this.$emit("pauseTimer", 1);

    var player = document.getElementById("videoPlayer");
    // setTimeout(() => { this.GameMove() }, this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage][this.video+'Time']);
  },
  data() {
    return {
      teamName: "",
    };
  },

  methods: {
    GameMove() {
      this.$emit("updatestage", "");
    },
    onEnd() {
      setTimeout(() => {
        this.GameMove();
      }, 2);
    },
  },
};
</script>


