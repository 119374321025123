<template>
  <div class="container">
    <!-- Text Box Section -->
    <div class="left-section">
      <input
        type="text"
        placeholder="Enter your text here"
        v-model="inputText"
        class="text-input"
      />
    </div>

    <!-- CSS Phone Section -->
    <div class="right-section">
      <div class="phone">
        <div class="phone-screen">
          <div class="phoneScreenCaller">
            Call from:<br /><br />
            HMRC
          </div>
          <div class="phoneButtons">
            <button class="answerBtn"><div class="checkmark"></div></button>
            <button class="rejectBtn"><div class="x">X</div></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      inputText: "",
    };
  },
};
</script>
  
  <style scoped>
/* Container Flex Layout */
.container {
  display: flex;
  flex-direction: row; /* Ensure they are side by side */
  align-items: center;
  justify-content: space-between; /* Spread items evenly */
  height: 100vh;
  width: 100vh;
  padding: 20px;
}

.left-section {
  flex: 1; /* Takes equal space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section {
  flex: 1; /* Takes equal space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

/* Phone CSS */
.phone {
  width: 200px;
  height: 400px;
  border: 5px solid #000;
  border-radius: 20px;
  position: relative;
}

.phoneRing {
  width: 200px;
  height: 400px;
  border: 5px solid #000;
  border-radius: 20px;
  position: relative;
  animation: shake 0.5s ease-in-out infinite alternate;
}

.phone-screen {
  width: 90%;
  height: 90%;
  background: #222;
  border-radius: 10px;
  margin: 15px auto;
}

.phone-antenna {
  width: 30%;
  height: 10px;
  background: #000;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
}

.phoneScreenCaller {
  color: #fff;
  font-size: 20px;
  padding: 5px;
  padding-top: 30px;
  text-align: center;
}

.phoneButtons {
  display: flex;
  justify-content: space-around;
  padding-top: 120px;
}

.answerBtn {
  height: 50px;
  line-height: 0px;
  width: 50px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  text-align: center;
  cursor: pointer;
  padding-top: -80px;
}

.rejectBtn {
  height: 50px;
  line-height: 0px;
  width: 50px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #af241d;
  color: white;
  text-align: center;
  cursor: pointer;
  padding-top: -80px;
}

.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 8px;
  border-bottom: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
}

.x {
  font-size: 30px;
}

/* Phone Animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  25% {
    transform: translate(-2px, -2px) rotate(-2deg);
  }
  50% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}
</style>
  